//auth-context.js
import React, { createContext, useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isAuthenticated, user, isLoading, loginWithRedirect, logout, handleRedirectCallback } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && user) {
      localStorage.setItem('user', JSON.stringify(user));
      console.log("User auth0 logged in:", user);
    } else {
      localStorage.removeItem('user');
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const processRedirectCallback = async () => {
      try {
        const { appState } = await handleRedirectCallback();
        if (appState && appState.targetUrl) {
          navigate(appState.targetUrl);
        } else {
          navigate("/my-account-registration");
        }
      } catch (error) {
        console.error('login Error handling redirect callback:', error);
      }
    };

    // Check if the URL contains the auth0 callback query parameters
    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
      processRedirectCallback();
    }
  }, [handleRedirectCallback, navigate, location.search]);

  const handleLogout = () => {
    console.log('user logout environment NODE_ENV:', process.env.NODE_ENV);
    const returnTo = process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_LOGOUT_URL
      : process.env.REACT_APP_PROD_LOGOUT_URL;
    logout({ logoutParams: { returnTo: returnTo } })
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, isLoading, loginWithRedirect, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
