// const { createSlice } = require('@reduxjs/toolkit');
//
// const productSlice = createSlice({
//     name: 'product',
//     initialState: {
//         products: [],
//     },
//     reducers: {
//         setProducts(state, action) {
//             state.products = action.payload;
//         }
//     },
// });
//
// export const { setProducts } = productSlice.actions;
// export default productSlice.reducer;


// store/slices/product-slice.js
import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    loading: false,
    error: null,
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
      state.loading = false;
      state.error = null;
    },
    // Handle other actions if needed
  },
});

export const { setProducts } = productSlice.actions;
export default productSlice.reducer;
