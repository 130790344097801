// UrlContext.js to remember user url before login
import { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const UrlContext = createContext();

export function UrlProvider({ children }) {
  const [previousLocation, setPreviousLocation] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Only set previous location if navigating away from other pages to LoginRegister
    if (location.pathname !== "/login-register") {
      setPreviousLocation(location.pathname);
    }
  }, [location]);

  return (
    <UrlContext.Provider value={{ previousLocation, setPreviousLocation }}>
      {children}
    </UrlContext.Provider>
  );
}

export function useUrlContext() {
  return useContext(UrlContext);
}
