// SupabaseContext.js

import React, {createContext, useContext} from 'react';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';

const SupabaseContext = createContext();

const supabase = createClient('https://yadlmosxtabtmdgiqoyd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlhZGxtb3N4dGFidG1kZ2lxb3lkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTYxMDY3NzEsImV4cCI6MjAxMTY4Mjc3MX0.FBNVWStDh_AE24Gr5hwP8edq6Gk-O5QXSjfu0Zx69l8')

export function SupabaseProvider({ children }) {

  return (
    <SupabaseContext.Provider value={{supabase, Auth, ThemeSupa}}>
      {children}
    </SupabaseContext.Provider>
  );
}

export function useSupabase() {
  return useContext(SupabaseContext);
}
